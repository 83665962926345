import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import imgHero from "../assets/image/conference/png/hero-main.png";
import imgC1 from "../assets/image/conference/jpg/content-1-img.jpg";
import imgCR from "../assets/image/conference/png/content-1-round.png";
import Content4 from "../sections/landing6/Content3";

const Hero = () => {
  return (
    <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
      {/* <!-- Hero Area --> */}
      <div className="bg-default-6 mt-28 position-relative">
        <div
          className="hero-area bg-image bg-blue  dark-mode-texts mx-6 mx-lg-9 rounded-15"
          css={`
            background-image: url(https://shadepro-gatsby.netlify.app/static/pattern-4-8263160699f4d9a09419f6536a57e66a.png);
          `}
        >
          <Container>
            <Row className="justify-content-sm-end">
              <Col lg="6" md="8">
                <div className="hero-content pe-xl-16 pt-18 pt-lg-31 pb-md-18 pb-lg-30 mb-9 mb-md-0">
                  <h1 className="title gr-text-2 mb-8 heading-color">Microservices &amp; Containerization</h1>
                  <p className="gr-text-8 mb-0 text-color-opacity">
                  We specialize in empowering businesses to adopt modern, scalable, and efficient software
                  architectures through microservices and containerization technologies.
                  </p>
                  <Button href="/contact" variant="success" className=" mt-11 btn btn-success">
                    Contact us
                  </Button>
                </div>
              </Col>
              <Col
                lg="5"
                md="4"
                sm="7"
                className="offset-lg-1 align-self-sm-end position-relative">
                <div
                  className="hero-img hero-img-custom z-index-1 mb-n12 mb-md-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="500"
              >
                </div>
              </Col>
            </Row>
          </Container>
        </div>
          </div>
        <div className="content-section pt-13 pt-lg-29 pb-12 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-center ">
            <Col className="ps-lg-15">
              <div className="section-title content-text mb-13 mb-lg-15">
                <h2 className="title gr-text-4 mb-6 heading-color">Our Core Offerings</h2>
                <p className="gr-text-8 pe-lg-12 mb-0 text-color-opacity">
                Our comprehensive solutions enable organizations to build, deploy, and manage applications
                with agility, resilience, and scalability.
                </p>
              </div>
              <div className="content-widget">             
                <Row className="mb-n11">
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        1. Microservices Architecture Design
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Leverage the power of microservices to break down monolithic applications into smaller,
                    independent services. We help you design scalable, loosely coupled architectures that
                    facilitate rapid development, deployment, and scalability.
                         </p>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        2. Container Orchestration
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Implement container orchestration platforms such as Kubernetes to manage and scale
                      your containerized applications efficiently. Our experts configure and optimize
                      Kubernetes clusters for high availability, resource utilization, and ease of management.
                      </p>
                    </div>
                </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        3. Dockerization of Applications
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Containerize your applications with Docker to achieve consistency and portability across
                      different environments. We assist in Dockerizing legacy applications or building new
                    microservices-based applications using Docker containers.
                      </p>
                    </div>
                  </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        4. Continuous Integration/Continuous Deployment (CI/CD) Pipelines
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Establish robust CI/CD pipelines for automating the build, test, and deployment
                      processes of microservices-based applications. Our solutions integrate seamlessly with
                      containerized environments to enable rapid and reliable software delivery.
                      </p>
                    </div>
                  </Col>
                 <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        5. Service Mesh Implementation
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Improve observability, security, and reliability of microservices communications with
                      service mesh solutions like Istio. We help you implement service mesh architectures to
                      manage traffic, enforce policies, and monitor service interactions.
                      </p>
                    </div>
                  </Col>               
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
    </div>
    <Content4 />
    </PageWrapper>
  );
};

export default Hero;